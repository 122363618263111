@font-face {
    font-family: 'Roihu-bold';
    src: url('./fonts/roihu-bold/subset-Roihu-Bold.woff2') format('woff2'),
    url('./fonts/roihu-bold/subset-Roihu-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roihu-black';
    src: url('./fonts/font-roihu-black/subset-Roihu-Black.woff2') format('woff2'),
    url('./fonts/font-roihu-black/subset-Roihu-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roihu-regular';
    src: url('./fonts/roihu-regular/subset-Roihu-Regular.woff2') format('woff2'),
    url('./fonts/roihu-regular/subset-Roihu-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roihu-medium';
    src: url('./fonts/roihu-bold-medium/subset-Roihu-Medium.woff2') format('woff2'),
    url('./fonts/roihu-bold-medium/subset-Roihu-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #D0CFCF;
    border: none;
    padding: 0px;
    margin: 0px;
    font-family: 'Roihu-regular', serif;
}

.relative{
    overflow: hidden;
}

body h1 {
    font-family: 'Roihu-bold', serif;
}

body h2 {
    font-family: 'Roihu-bold', serif;
}

body h3 {
    font-family: 'Roihu-bold', serif;
}

body h4 {
    font-family: 'Roihu-bold', serif;
}

body h5 {
    font-family: 'Roihu-bold', serif;
}

body h6 {
    font-family: 'Roihu-bold', serif;
}

body p {
    font-family: 'Roihu-regular', serif;
}

body a{
    font-family: 'Roihu-bold', serif;
}

